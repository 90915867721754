import React from 'react';
import Image from 'Nori/Image';
import styles from 'Custom/Hero/index.module.scss';
import ItemBreakpoint from 'Custom/ItemBreakpoint/index';

const HERO_VARIANTS = {
  overlap: styles.overlap,
  cover: styles.cover,
  main: styles.main,
  mainSpanish: styles.mainSpanish,
  default: styles.cover,
};

const HERO_BACKGROUND = {
  primaryLight: styles.primaryLight,
  secondary2Light: styles.secondary2Light,
  secondary4Light: styles.secondary4Light,
  noBackground: styles.noBackground,
  default: styles.noBackground,
};

const HeroImage = ({ image = null, caption = '', ...props }) => {
  return (
    image && (
      <figure className={styles.heroImage} {...props}>
        <Image {...image} />
        {caption && <figcaption>{caption}</figcaption>}
      </figure>
    )
  );
};

const Hero = ({
  className = '',
  background = '',
  caption = '',
  title = '',
  variant = '',
  image = null,
  mobileImage = null,
  imageBreakpoint = 'lg',
  children,
  ...props
}) => {
  const backgroundClassName =
    HERO_BACKGROUND[background] || HERO_BACKGROUND.default;
  const variantClassName = HERO_VARIANTS[variant] || HERO_VARIANTS.default;

  return (
    <div
      className={`${styles.hero} ${backgroundClassName} ${variantClassName} ${className}`}
      {...props}
    >
      <div className={styles.heroLeft}>
        <div className={styles.heroContent}>
          {title && <h1 className={styles.heroMainTitle}>{title}</h1>}
          <div className={styles.heroChildren}>{children}</div>
        </div>
      </div>
      {image && (
        <div className={styles.heroRight}>
          {mobileImage ? (
            <ItemBreakpoint breakpoint={imageBreakpoint}>
              <HeroImage image={image} caption={caption} />
              <HeroImage image={mobileImage} caption={caption} />
            </ItemBreakpoint>
          ) : (
            <HeroImage image={image} caption={caption} />
          )}
        </div>
      )}
    </div>
  );
};

export default Hero;
