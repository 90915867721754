import React, { isValidElement, useRef } from 'react';
import { GENERAL_JOB_CODE } from 'Custom/Footer/Footer.utils.js';
import BaseLayout from 'Nori/Layout/BaseLayout';
import Footer from 'Custom/Footer';
import Header from 'Custom/Header';
import StickyNavigation from 'Custom/Header/StickyNavigation';
import NurseNavigator from 'Custom/NurseNavigator';
import ISIFooter from 'Custom/ISI/ISIFooter';
import ISIBar from 'Custom/ISIBar';
import ExternalLinkModal from 'Custom/Modal/ExternalLinkModal';
import HealthcareModal from 'Custom/Modal/HealthcareModal';
import ISIModal from 'Custom/Modal/ISIModal';
import ModalPDF from 'Custom/ModalPDF/index';
import { useAppContext } from 'Providers/App';

const PageReference = () => null;
const AltLayout = ({
  reference: Reference = PageReference,
  className = '',
  hideISI = false,
  basic = false,
  jobCode = GENERAL_JOB_CODE,
  showChatbox = true,
  children,
  ...props
}) => {
  const { modalSNISI, setModalSNISI, modalPaginator } = useAppContext();
  const isiRef = useRef(null);

  return (
    <BaseLayout isiRef={isiRef} {...props}>
      <Header basic={basic} />
      {showChatbox && <NurseNavigator />}
      {!hideISI && <ISIBar />}
      <StickyNavigation basic={basic} />

      <main className={className}>{children}</main>

      {!hideISI && isValidElement(<Reference />) && (
        <ISIFooter reference={Reference} ref={isiRef} />
      )}

      <Footer basic={basic} jobCode={jobCode} />

      <ISIModal />
      <ExternalLinkModal />
      <HealthcareModal />
      <ModalPDF
        isOpen={modalSNISI}
        onClose={() => {
          setModalSNISI(false);
        }}
        paginator={modalPaginator}
      />
    </BaseLayout>
  );
};

export default AltLayout;
