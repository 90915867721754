import React from 'react';
import dynamic from 'node_modules/next/dynamic';
import styles from 'Custom/ModalPDF/index.module.scss';
import Modal from 'Nori/Modal';
import CloseIcon from '@/public/assets/images/brand-icons/close-modal-nexplanon.svg';
import { useAppContext } from 'Providers/App';
import Button from 'Nori/Button';
import RichText from 'Custom/RichText/index';

const DEFAULT_PDF = '/pdf/n_nexplanon_nexplanon_pi_ppi_combo.pdf';
const PDF_MODAL_JOB = 'US-XPL-117380 10/24';

const PDFViewer = dynamic(() => import('Custom/PDFViewer'), {
  ssr: false,
});

const ModalPDF = ({
  className = '',
  pdf = DEFAULT_PDF,
  paginator = true,
  ...props
}) => {
  const { setModalSNISI } = useAppContext();

  const handleClick = () => {
    setModalSNISI(false);
  };

  return (
    <Modal
      className={`${styles.pdfModal} ${className}`}
      {...props}
      data-design-visible="visible_vd"
      data-design-type="module"
      data-design-nori_id="Modal"
      data-design-module_name="ISI PDF"
      data-design-module_no="00"
    >
      <button className={styles.closeIcon} onClick={handleClick}>
        <CloseIcon
          data-design-category="button_vd"
          data-design-type="clickAction"
          data-design-action="Close"
          data-design-label="close icon"
          data-design-nori_id="Modal"
          data-design-module_name="ISI PDF"
          data-design-module_no="00"
        />
      </button>
      <div className={styles.modalContent}>
        <div className={styles.pdfWrapper}>
          <PDFViewer pdf={pdf} paginator={paginator} />
        </div>
        <RichText className={styles.jobCode}>
          <p>{PDF_MODAL_JOB}</p>
        </RichText>
      </div>
    </Modal>
  );
};

export default ModalPDF;
