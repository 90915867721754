import RichText from 'Custom/RichText';
import Link from 'node_modules/next/link';

const FAQ_USERS_1_LIST = [
  'Pain in your lower leg that does not go away',
  'Severe chest pain or heaviness in your chest',
  'Sudden shortness of breath, sharp chest pain, or coughing blood',
  'Symptoms of a severe allergic reaction, such as swollen face, tongue or throat, trouble breathing or swallowing',
  'Sudden severe headache unlike your usual headaches',
  'Weakness or numbness in your arm, leg, or trouble speaking',
  'Sudden partial or complete blindness',
  'Yellowing of your skin or whites of your eyes, especially with fever, tiredness, loss of appetite, dark-colored urine, or light-colored bowel movements',
  'Severe pain, swelling, or tenderness in the lower stomach (abdomen)',
  'Lump in your breast',
  'Problems sleeping, lack of energy, tiredness, or you feel very sad',
  'Heavy menstrual bleeding',
  'Feeling that the implant may have broken or bent while in your arm',
];

export const FAQ_USERS_2_LIST = [
  'Aprepitant',
  'Barbiturates',
  'Bosentan',
  'Carbamazepine',
  'Felbamate',
  'Griseofulvin',
  'Oxcarbazepine',
  'Phenytoin',
  'Rifampin',
  'St. John’s wort',
  'Topiramate',
  'HIV medicines',
  'Hepatitis C virus medicines',
];

const FAQ_USERS_3_LIST_1 = [
  'Are pregnant or think you may be pregnant',
  'Have, or have had blood clots, such as blood clots in your legs (deep vein thrombosis), lungs (pulmonary embolism), eyes (total or partial blindness), heart (heart attack), or brain (stroke)',
  'Have liver disease or a liver tumor',
  'Have unexplained vaginal bleeding',
  'Have breast cancer or any other cancer that is sensitive to progestin (a female hormone), now or in the past',
  'Are allergic to anything in NEXPLANON',
];

const FAQ_USERS_3_LIST_2 = [
  'Diabetes',
  'High cholesterol or triglycerides',
  'Headaches',
  'Gallbladder or kidney problems',
  'A history of depressed mood',
  'High blood pressure',
  'An allergy to numbing medicines (anesthetics) or medicines used to clean your skin (antiseptics). These medicines will be used when the implant is placed into or removed from your arm.',
];

const FAQ_EXPLORING_QUESTION_1 = {
  title: 'is NEXPLANON a long-acting birth control?',
  content: (
    <RichText>
      <p>
        Yes, NEXPLANON is a small (about the size of a matchstick), thin, and
        flexible birth control implant that provides 3 years* of continuous
        pregnancy prevention. It’s placed discreetly just under the skin on the
        inner side of your non-dominant upper arm by a trained healthcare
        professional.
      </p>
      <p className="caption">
        *NEXPLANON must be removed by the end of the third year.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_2 = {
  title: 'is NEXPLANON an IUD?',
  content: (
    <RichText>
      <p>
        No, it's not an intrauterine device (IUD). It is an implant that is
        placed in your arm. Like an IUD, it's a long-acting birth control
        option. NEXPLANON lasts for 3 years.*
      </p>
      <p className="caption">
        *NEXPLANON must be removed by the end of the third year.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_3 = {
  title: 'how big is NEXPLANON?',
  content: (
    <RichText>
      <p>
        NEXPLANON is 1.6 inches (4 centimeters) in length, about the size of a
        matchstick.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_4 = {
  title: 'how does NEXPLANON work?',
  content: (
    <RichText>
      <p>
        NEXPLANON is a hormone-releasing birth control implant for use by women
        to prevent pregnancy for up to 3 years.*
      </p>
      <p>
        NEXPLANON prevents pregnancy in several ways. The most important way is
        by stopping the release of an egg from your ovary. NEXPLANON also
        thickens the mucus in your cervix and this change may keep sperm from
        reaching the egg.
      </p>
      <p>NEXPLANON also changes the lining of your uterus.</p>

      <p className="caption">
        *NEXPLANON must be removed by the end of the third year.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_5 = {
  title: 'does NEXPLANON contain estrogen?',
  content: (
    <RichText>
      <p>No. NEXPLANON does not contain estrogen.</p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_6 = {
  title: 'does NEXPLANON work as well as the pill?',
  content: `NEXPLANON is over 99% effective (less than 1 pregnancy per 100 women who used NEXPLANON for 1 year) at preventing pregnancy. NEXPLANON is one of the`,
  linkLabel: 'most effective forms of birth control available',
  link: '/effectiveness/',
};

const FAQ_EXPLORING_QUESTION_6_FAQ = {
  title: FAQ_EXPLORING_QUESTION_6.title,
  content: (
    <RichText>
      <p>
        {`${FAQ_EXPLORING_QUESTION_6.content} `}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label={FAQ_EXPLORING_QUESTION_6.linkLabel}
          data-design-nori_id="Accordion"
          data-design-module_name="exploring NEXPLANON"
          data-design-module_no="02"
          data-design-element_no="06"
          href={FAQ_EXPLORING_QUESTION_6.link}
        >
          {FAQ_EXPLORING_QUESTION_6.linkLabel}
        </Link>
        .
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_6_HOME = {
  title: FAQ_EXPLORING_QUESTION_6.title,
  content: (
    <RichText>
      <p>
        {`${FAQ_EXPLORING_QUESTION_6.content} `}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label={FAQ_EXPLORING_QUESTION_6.linkLabel}
          data-design-nori_id="Accordion"
          data-design-module_name="questions to talk through with your doctor"
          data-design-module_no="06"
          data-design-element_no="05"
          href={FAQ_EXPLORING_QUESTION_6.link}
        >
          {FAQ_EXPLORING_QUESTION_6.linkLabel}
        </Link>
        .
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_7 = {
  title: 'what if I want NEXPLANON for more than 3 years?',
  content: (
    <RichText>
      <p>
        The NEXPLANON implant must be removed by the end of the third year. A
        trained healthcare professional can insert a new implant under your skin
        after removing the old one if you choose to continue using NEXPLANON for
        birth control.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_8 = {
  title:
    'what if I change my mind and want to stop using NEXPLANON before 3 years?',
  content: (
    <RichText>
      <p>
        A trained healthcare professional can remove the implant at any time
        within the 3-year period. You may become pregnant as early as the first
        week after removal of the implant. If you do not want to get pregnant
        after removal of the NEXPLANON implant, you should start another birth
        control method, such as condoms, immediately.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_9 = {
  title: 'does NEXPLANON affect my period?',
  content: (
    <RichText>
      <p>
        The most common side effect of NEXPLANON is a change in your normal
        menstrual bleeding pattern. In studies, 1 in 10 women stopped using the
        implant because of an unfavorable change in their bleeding pattern. Your
        period may be longer or shorter, or you may have no bleeding at all. The
        time between periods may vary, and you may also have spotting in between
        periods.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_10 = {
  title: 'when should I have NEXPLANON inserted in my arm?',
  content: (
    <RichText>
      <p>
        Your healthcare professional/doctor will help you determine when to have
        NEXPLANON inserted. The timing will depend on whether you are currently
        using birth control and which method you are using. You should not use
        NEXPLANON if you are pregnant or think you may be pregnant.
      </p>
    </RichText>
  ),
};

const FAQ_EXPLORING_QUESTION_11 = {
  title: 'how do I get NEXPLANON removed?',
  content: (
    <RichText>
      <p>
        NEXPLANON can be removed at any time by a trained healthcare
        professional in a minor surgical procedure during the 3-year period, but
        must be removed by the end of the third year. For more information about
        how to get NEXPLANON removed,{' '}
        <Link
          data-design-category="content_link_vd"
          data-design-type="clickAction"
          data-design-action="Link"
          data-design-label="click here"
          data-design-nori_id="Accordion"
          data-design-module_name="exploring NEXPLANON"
          data-design-module_no="02"
          data-design-element_no="11"
          href="/removal/"
        >
          click here
        </Link>
        .
      </p>
    </RichText>
  ),
};

export const FAQ_EXPLORING_QUESTIONS = [
  FAQ_EXPLORING_QUESTION_1,
  FAQ_EXPLORING_QUESTION_2,
  FAQ_EXPLORING_QUESTION_3,
  FAQ_EXPLORING_QUESTION_4,
  FAQ_EXPLORING_QUESTION_5,
  FAQ_EXPLORING_QUESTION_6_FAQ,
  FAQ_EXPLORING_QUESTION_7,
  FAQ_EXPLORING_QUESTION_8,
  FAQ_EXPLORING_QUESTION_9,
  FAQ_EXPLORING_QUESTION_10,
  FAQ_EXPLORING_QUESTION_11,
];

export const FAQ_HOME_QUESTIONS = [
  FAQ_EXPLORING_QUESTION_1,
  FAQ_EXPLORING_QUESTION_2,
  FAQ_EXPLORING_QUESTION_3,
  FAQ_EXPLORING_QUESTION_4,
  FAQ_EXPLORING_QUESTION_6_HOME,
  FAQ_EXPLORING_QUESTION_8,
];

export const FAQ_NEXPLANON_USERS = [
  {
    title: 'when should I call my healthcare professional?',
    content: (
      <RichText>
        <p>Call your healthcare professional right away if you have:</p>
        {FAQ_USERS_1_LIST && (
          <ul className="tightened">
            {FAQ_USERS_1_LIST.map((item, i) => (
              <li key={`faq-users-${i}`}>{item}</li>
            ))}
          </ul>
        )}
      </RichText>
    ),
  },
  {
    title: 'what medicines can interact with NEXPLANON?',
    content: (
      <RichText>
        <p>
          Tell your healthcare professional about all the medicines you take,
          including prescription and nonprescription medicines, vitamins, and
          herbal supplements. Certain medicines may make NEXPLANON less
          effective, including:
        </p>
        {FAQ_USERS_2_LIST && (
          <ul className="tightened">
            {FAQ_USERS_2_LIST.map((item, i) => (
              <li key={`faq-users-2-${i}`}>{item}</li>
            ))}
          </ul>
        )}
        <p>
          Ask your healthcare professional if you’re not sure if your medicine
          is listed above.
        </p>
        <p>
          If you are taking medicines or herbal products that might make
          NEXPLANON less effective, you and your doctor may decide to leave
          NEXPLANON in place; in that case, an additional, non-hormonal
          contraceptive should be used. Because the effect of another medicine
          on NEXPLANON may last up to 28 days after stopping the medicine, it is
          necessary to use the additional, non-hormonal contraceptive for that
          long.
        </p>
        <p>
          When you’re using NEXPLANON, tell all healthcare professionals that
          you have NEXPLANON in place in your arm.
        </p>
      </RichText>
    ),
  },
  {
    title: 'who should not use NEXPLANON?',
    content: (
      <RichText>
        <p>
          <Link
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-label="Do not use NEXPLANON"
            data-design-nori_id="Accordion"
            data-design-module_name="NEXPLANON Users"
            data-design-module_no="03"
            data-design-element_no="03"
            href="/side-effects/"
          >
            Do not use NEXPLANON
          </Link>{' '}
          if you:
        </p>
        {FAQ_USERS_3_LIST_1 && (
          <ul className="tightened">
            {FAQ_USERS_3_LIST_1.map((item, i) => (
              <li key={`faq-users-3-1-${i}`}>{item}</li>
            ))}
          </ul>
        )}
        <p>
          Tell your healthcare professional if you have or have had any of the
          conditions listed above. Your healthcare professional can suggest a
          different method of birth control.
        </p>
        <p>
          In addition, talk to your healthcare professional about using
          NEXPLANON if you have:
        </p>
        {FAQ_USERS_3_LIST_2 && (
          <ul className="tightened">
            {FAQ_USERS_3_LIST_2.map((item, i) => (
              <li key={`faq-users-3-1-${i}`}>{item}</li>
            ))}
          </ul>
        )}
      </RichText>
    ),
  },
  {
    title: 'what if I become pregnant while using NEXPLANON?',
    content: (
      <RichText>
        <p>
          You should see a healthcare professional right away if you think that
          you might be pregnant. It’s important to remove NEXPLANON and make
          sure that the pregnancy is not ectopic (occurring outside the womb).
          Based on experience with other hormonal contraceptives, NEXPLANON is
          not likely to cause birth defects.
        </p>
      </RichText>
    ),
  },
  {
    title: 'can I use NEXPLANON when I am breastfeeding?',
    id: 'breastfeeding',
    content: (
      <RichText>
        <p>
          If you’re breastfeeding your child, you may use NEXPLANON if 4 weeks
          have passed since you had your baby. A small amount of the hormone
          contained in NEXPLANON passes into your breast milk. The health of
          breastfed children whose mothers were using the implant has been
          studied up to 3 years of age in a small number of children. No effects
          on the growth and development of the children were seen. If you’re
          breastfeeding and want to use NEXPLANON, talk with a healthcare
          professional for more information.
        </p>
      </RichText>
    ),
  },
  {
    title: 'will NEXPLANON be covered by insurance?',
    content: (
      <RichText>
        <p>
          You may be able to get NEXPLANON for free under the Affordable Care
          Act. Check with your insurance plan for{' '}
          <Link
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-label="cost"
            data-design-nori_id="Accordion"
            data-design-module_name="NEXPLANON Users"
            data-design-module_no="03"
            data-design-element_no="06"
            href="/cost/"
          >
            cost
          </Link>{' '}
          and{' '}
          <Link
            data-design-category="content_link_vd"
            data-design-type="clickAction"
            data-design-action="Link"
            data-design-label="insurance information"
            data-design-nori_id="Accordion"
            data-design-module_name="NEXPLANON Users"
            data-design-module_no="03"
            data-design-element_no="06"
            href="/insurance-coverage/"
          >
            insurance information
          </Link>
          . You may need to pay a co-pay, deductible, or other charges.
        </p>
      </RichText>
    ),
  },
];
