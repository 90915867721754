import React from 'react';
import styles from 'Custom/NurseNavigator/index.module.scss';
import ChatBubbleIcon from 'Images/brand-icons/chat-bubble-icon.svg';

import { useAppContext } from 'Providers/App';

const NurseNavigator = ({ className = '', href = '', children, ...props }) => {
  const { isISIVisible } = useAppContext();
  const styleClassName = isISIVisible ? styles.compacted : '';

  const openChat = () => {
    window.openChat();
    console.log('Executing window.openChat');
  };

  return (
    <button
      onClick={openChat}
      className={`${styles.nurseNavigator} ${styleClassName} ${className}`}
      {...props}
    >
      <span
        className={styles.nurseLabel}
        data-design-type="clickAction"
        data-design-category="chatbot"
        data-design-action="Open"
        data-design-label="Chat with a Nurse Navigator"
        data-design-nori_id="Chatbot"
        data-design-module_name="Chat with a Nurse Navigator"
        data-design-module_no="00"
      >
        Chat with a Nurse Navigator
      </span>
      <span className={styles.nurseBubble}>
        <ChatBubbleIcon
          data-design-type="clickAction"
          data-design-category="chatbot"
          data-design-action="Open"
          data-design-label="Chat with a Nurse Navigator"
          data-design-nori_id="Chatbot"
          data-design-module_name="Chat with a Nurse Navigator"
          data-design-module_no="00"
          aria-label="Chat with a Nurse Navigator"
        />
      </span>
    </button>
  );
};

export default NurseNavigator;
